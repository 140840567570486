const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
const tooltipList = [...tooltipTriggerList].map(function (tooltipTriggerEl) {
  return new bootstrap.Tooltip(tooltipTriggerEl)
})

const toastElList = document.querySelectorAll('.toast')
const toastList = [...toastElList].map(function (toastEl) {
    var toast =  new bootstrap.Toast(toastEl)
    toast.show()
    return toast
})


// // Удаляем параметры сортировки из url, если в sort_by пусто(в select выбрано значение по умолчанию)
// let url = new URL(window.location.href);
// let params = url.searchParams;
// if (url.searchParams.get('sort_by') == '') {
//     url.searchParams.delete('sort_by');
//     url.searchParams.delete('order');
// }
// // console.log(url);
// window.history.pushState({}, document.title, url);

// Смена направления сортировки по кнопке
function toggleOrder(order) {
    var element = document.getElementById(order);
    if (element.value == "asc") {
        element.value = "desc";
    } else {
        element.value = "asc";
    }
}


$(".select2").select2({
    theme: "bootstrap-5",
    templateSelection: function (data) {
        var result = $(data.element).attr('data-selections');
        if (!result) {
            result = data.text;
        }
        return result;
    },
    templateResult: function (data) {
        result = '<div>' + data.text + '</div>';
        var secondLine = $(data.element).attr('data-second-line');
        if (secondLine) {
            result = result + '<div class="text-muted fs-sm">' + secondLine + '</div>';
        }
        return result;
    },
    escapeMarkup: function(m) { return m; }
});



/*!
 * Color mode toggler for Bootstrap's docs (https://getbootstrap.com/)
 * Copyright 2011-2023 The Bootstrap Authors
 * Licensed under the Creative Commons Attribution 3.0 Unported License.
 */

// (() => {
//     'use strict'

//     const storedTheme = localStorage.getItem('theme')

//     const getPreferredTheme = () => {
//       if (storedTheme) {
//         return storedTheme
//       }

//       return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
//     }

//     const setTheme = function (theme) {
//       if (theme === 'auto' && window.matchMedia('(prefers-color-scheme: dark)').matches) {
//         document.documentElement.setAttribute('data-bs-theme', 'dark')
//       } else {
//         document.documentElement.setAttribute('data-bs-theme', theme)
//       }
//     }

//     setTheme(getPreferredTheme())




//     const showActiveTheme = (theme, focus = false) => {
//       const themeSwitcher = document.querySelector('#bd-theme')

//       if (!themeSwitcher) {
//         return
//       }

//       const themeSwitcherText = document.querySelector('#bd-theme-text')
//       const activeThemeIcon = document.querySelector('.theme-icon-active use')
//       const btnToActive = document.querySelector(`[data-bs-theme-value="${theme}"]`)
//       const svgOfActiveBtn = btnToActive.querySelector('svg use').getAttribute('href')

//       document.querySelectorAll('[data-bs-theme-value]').forEach(element => {
//         element.classList.remove('active')
//         element.setAttribute('aria-pressed', 'false')
//       })

//       btnToActive.classList.add('active')
//       btnToActive.setAttribute('aria-pressed', 'true')
//       activeThemeIcon.setAttribute('href', svgOfActiveBtn)
//       const themeSwitcherLabel = `${themeSwitcherText.textContent} (${btnToActive.dataset.bsThemeValue})`
//       themeSwitcher.setAttribute('aria-label', themeSwitcherLabel)

//       if (focus) {
//         themeSwitcher.focus()
//       }
//     }

//     window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', () => {
//       if (storedTheme !== 'light' || storedTheme !== 'dark') {
//         setTheme(getPreferredTheme())
//       }
//     })

//     window.addEventListener('DOMContentLoaded', () => {
//       showActiveTheme(getPreferredTheme())

//       document.querySelectorAll('[data-bs-theme-value]')
//         .forEach(toggle => {
//           toggle.addEventListener('click', () => {
//             const theme = toggle.getAttribute('data-bs-theme-value')
//             localStorage.setItem('theme', theme)
//             setTheme(theme)
//             showActiveTheme(theme, true)
//           })
//         })
//     })
//   })()
